import * as React from "react"
import PropTypes from "prop-types"
import './header.scss'
import MapIcon from '../images/icons/map-icon.svg'
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { AnchorLink } from "gatsby-plugin-anchor-links";





export default function Header({ siteTitle }) {

  const [hamburgerOpen, setHamburgerOpen] = React.useState(false);
  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen)
  }



  return (
    <>
      <div className={hamburgerOpen ? ["hamburger-nav", "hamburger-nav-open"].join(" ") : "hamburger-nav"}>
        <AniLink
          paintDrip
          to="/events"
          duration={1.5}
          hex="#000000"
          activeClassName='active-link'
        >
          Rondleidingen & Events
        </AniLink>
        <AniLink
          paintDrip
          to="/"
          duration={1.5}
          hex="#000000"
          activeClassName='active-link'
        >
          Sneak Peek
        </AniLink>
        <AniLink
          paintDrip
          to="/organizations"
          duration={1.5}
          hex="#000000"
          activeClassName='active-link'
        >
          Organisaties
        </AniLink>
        <AnchorLink to="https://dekoepel.sumupstore.com/">
          Webshop
        </AnchorLink>
        <AnchorLink to="/#contact-outer-container">
          Contact
        </AnchorLink>
        <div className='address-container'>
          <p><a className="google-maps-link" href="https://www.google.nl/maps/search/Koepelplein/@52.3833648,4.6434867,17z/data=!3m1!4b1" target="_blank" rel="noreferrer">Koepelplein 1 Haarlem</a><span><MapIcon /></span></p>
        </div>
      </div>
      <header>
        <div className='visible-header'>
          <div className='header-container'>
            <div className='outer-nav-links-container'>
              <div className='inner-nav-links-container'>
                <div className={hamburgerOpen ? ["hamburger-container", "hamburger-container-open"].join(" ") : "hamburger-container"} onClick={toggleHamburger} onKeyDown={toggleHamburger} aria-label="menu" role="menu" tabIndex={0}>
                  <div className="hamburger-line" ></div>
                  <div className="hamburger-line" ></div>
                  <div className="hamburger-line" ></div>
                </div>
                <div className="visible-links-container">
                  <AniLink
                    paintDrip
                    to="/events"
                    duration={1.5}
                    hex="#000000"
                    activeClassName='active-link'
                  >
                    Rondleidingen & Events
                  </AniLink>
                  <AniLink
                    paintDrip
                    to="/"
                    duration={1.5}
                    hex="#000000"
                    activeClassName='active-link'
                  >
                    Sneak Peek
                  </AniLink>
                  <AniLink
                    paintDrip
                    to="/organizations"
                    duration={1.5}
                    hex="#000000"
                    activeClassName='active-link'
                  >
                    Organisaties
                  </AniLink>
                  <AnchorLink to="https://dekoepel.sumupstore.com/">
                    Webshop
                  </AnchorLink>
                  <AnchorLink to="/#contact-outer-container">
                    Contact
                  </AnchorLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='hidden-nav'>
          <div className='header-container'>
          <AniLink
          paintDrip
          to="/"
          duration={1.5}
          hex="#000000"
          className='hidden-title'
          >
            <h2>Koeoe</h2>
          </AniLink>
            <div className="p-fix"></div>
            <div className='outer-nav-links-container'>
              <div className='inner-nav-links-container'>
                <div className={hamburgerOpen ? ["hamburger-container-bottom", "hamburger-container-bottom-open"].join(" ") : "hamburger-container-bottom"} onClick={toggleHamburger} onKeyDown={toggleHamburger} aria-label="menu" role="menu" tabIndex={0}>
                  <div className="hamburger-line-bottom" ></div>
                  <div className="hamburger-line-bottom" ></div>
                  <div className="hamburger-line-bottom" ></div>
                </div>
                <div className="hidden-links-container">
                <AniLink
                  paintDrip
                  to="/events"
                  duration={1.5}
                  hex="#000000"
                  activeClassName='active-link'
                >
                  Rondleidingen & Events
                </AniLink>
                <AniLink
                  paintDrip
                  to="/"
                  duration={1.5}
                  hex="#000000"
                  activeClassName='active-link'
                >
                  Sneak Peek
                </AniLink>
                <AniLink
                  paintDrip
                  to="/organizations"
                  duration={1.5}
                  hex="#000000"
                  activeClassName='active-link'
                >
                  Organisaties
                </AniLink>
                <AnchorLink to="https://dekoepel.sumupstore.com/">
                  Webshop
                </AnchorLink>
                <AnchorLink to="/#contact-outer-container">
                    Contact
                </AnchorLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}





